export const baseURL = new URL(import.meta.env.BASE_URL, window.origin);
export const absoluteBaseRoot = baseURL.href;

export const FILE_STORAGE_ENDPOINT = import.meta.env.VITE_APP_FILE_STORAGE_ENDPOINT;
export const GRAPHQL_ENDPOINT = import.meta.env.VITE_APP_GRAPHQL_HTTP_ENDPOINT;
export const DJANGO_API = import.meta.env.VITE_APP_DJANGO_API_ENTRYPOINT;

export const EMPLOYEE_UPDATE_ENTRYPOINT = DJANGO_API + '/employee';
export const BOOKING_ENTRYPOINT = DJANGO_API + '/bookings';
export const LISTING_ENTRYPOINT = DJANGO_API + '/listings';
export const CREDITS_ENTRYPOINT = DJANGO_API + '/credits';
export const EMAILS_ENTRYPOINT = DJANGO_API + '/email';
export const STORAGE_ENTRYPOINT = DJANGO_API + '/storage';
