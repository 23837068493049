import type { PropsWithChildren, ReactElement } from 'react';
//
import { useLayoutEffect } from 'react';
import { AuthenticationError, useAuth0 } from '@auth0/auth0-react';

interface AuthError extends AuthenticationError {
    error_description: string;
    appState: {
        returnTo: string;
    };
}
const noop = () => <></>;
type PropsType = PropsWithChildren<{
    onErrorRender?: (error_description: string, returnTo: string) => ReactElement;
    onLoadingElement?: ReactElement;
}>;
export const ProtectedRoute = ({ onErrorRender = noop, onLoadingElement, children }: PropsType) => {
    const { isLoading, error, isAuthenticated, loginWithRedirect } = useAuth0();

    useLayoutEffect(() => {
        if (isLoading || isAuthenticated || error) return;
        loginWithRedirect({
            appState: {
                returnTo: window.location.href,
            },
        });
    }, [isLoading, isAuthenticated, loginWithRedirect, error]);

    if (error) {
        const auth0Error = error as AuthError;
        return onErrorRender(auth0Error.error_description, auth0Error.appState.returnTo);
    }

    return isAuthenticated ? children : onLoadingElement;
};
