import type { FC } from 'react';
//
import { useAuth0 } from '@auth0/auth0-react';
import { absoluteBaseRoot } from 'app-constants.ts';

export const LoginRestricted: FC<{
    message?: string;
}> = ({ message }) => {
    const { logout } = useAuth0();
    window.history.replaceState(null, '', window.location.origin + window.location.pathname);

    const signOut = () =>
        logout({ logoutParams: { returnTo: absoluteBaseRoot } }).catch(console.error);

    return (
        <div className="overflow-auto">
            <header className="mb-7 mt-15">
                <h1 className="my-2 p-2 text-center text-5xl font-medium">Access Denied</h1>
            </header>
            <p className="my-6 text-center text-3xl font-medium">{message}</p>

            <div className="text-center ">
                <button className="text-xl underline underline-offset-2" onClick={signOut}>
                    Sign out
                </button>
            </div>
        </div>
    );
};
