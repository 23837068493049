import type { FC, PropsWithChildren } from 'react';
//
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
//
import { AUTH_CONFIG } from './auth0-config';

export const AuthProvider: FC<
    PropsWithChildren<
        {
            redirectUri?: string;
        } & Partial<Auth0ProviderOptions>
    >
> = ({ redirectUri, children, ...props }) => {
    return (
        <Auth0Provider
            clientId={AUTH_CONFIG.clientId}
            domain={AUTH_CONFIG.domain}
            authorizationParams={{
                redirect_uri: redirectUri ?? AUTH_CONFIG.redirectUri,
                audience: AUTH_CONFIG.audience,
                scope: AUTH_CONFIG.scope,
            }}
            {...props}
        >
            {children}
        </Auth0Provider>
    );
};
