import type { FC, ReactElement } from 'react';

const Loader: FC<{
    isLoading?: boolean;
    comment?: string | ReactElement;
    noBg?: boolean;
}> = ({ isLoading = true, comment, noBg = false }) => {
    return isLoading ? (
        <div
            className={`
                absolute inset-0 z-99
                flex flex-col items-center justify-center
                ${!noBg ? 'bg-white bg-opacity-65 dark:bg-boxdark dark:bg-opacity-65' : ''}
            `}
        >
            <div className="relative">
                <div className="h-16 w-16 animate-spin rounded-full border-[0.25rem] border-solid border-primary border-t-transparent"></div>
                {comment && (
                    <div
                        className={`
                            x-center absolute top-full mt-3 
                            ${typeof comment === 'string' ? 'whitespace-nowrap' : ''}
                        `}
                    >
                        {comment}
                    </div>
                )}
            </div>
        </div>
    ) : null;
};

export default Loader;
