/* eslint-disable  react-refresh/only-export-components */
/// <reference types="vite-plugin-svgr/client" />
import type { AppState } from '@auth0/auth0-react';
//
import ReactDOM from 'react-dom/client';
import { Suspense, lazy } from 'react';
//
import './index.css';

import { AuthProvider, ProtectedRoute } from 'Services/auth';
import { LoginRestricted } from 'Pages/LoginRestricted.tsx';
import Loader from 'Components/Loader.tsx';

const Main = lazy(() => import('Main.tsx'));

ReactDOM.createRoot(document.getElementById('root')!).render(
    // <StrictMode>
    <AuthProvider
        onRedirectCallback={(appState?: AppState) => {
            appState && history.replaceState(null, '', appState.returnTo);
        }}
    >
        <ProtectedRoute
            onErrorRender={(errorMsg) => <LoginRestricted message={errorMsg} />}
            onLoadingElement={<Loader comment="Authenticating..." />}
        >
            <Suspense fallback={<Loader comment="App Loading..." />}>
                <Main />
            </Suspense>
        </ProtectedRoute>
    </AuthProvider>
    // </StrictMode>
);

console.info(import.meta.env.PACKAGE_VERSION);
